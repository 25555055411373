.MuiFormHelperText-contained {
  margin-left: 0;
  margin-top: 6px;
  color: #000000;
  font-size: 10px
}

.MuiFormHelperText-root {
  color: #DA1414
}

.MuiInput-underline::before {
  border-bottom: none !important
}

.MuiInput-underline::after {
  border-bottom: 1px solid #f9b233 !important
}